





















































































































































































































.eggsbook-fx {
  h2.title {
    font-size: clamp(1.2rem, 3vw, 2.2rem);
  }
  .section-1 {
    background: url('~@/assets/images/background/1.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
    box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
    padding: 150px 0 150px;
    min-height: 50vh;
    position: relative;
    .content-wyb {
      color: #fff;
      padding: 4rem 2rem 4rem 2rem;
      p {
        font-weight: 400;
        letter-spacing: 0.3px;
        line-height: 110%;
      }
    }
    .content-img-wyb-1 {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      max-width: 800px;
      img {
        // max-height: 385px;
        height: auto;
        width: 100%;
        border-radius: 5px;
      }
    }

    @media screen and (min-width: 1441px) {
      .content-wyb {
        p {
          font-size: 1.125rem;
          line-height: 150%;
        }
      }
    }
    @media (max-width: 1440px) {
      padding: 150px 0 100px;
      h2.title {
        font-size: clamp(1.2rem, 3vw, 1.6rem);
      }
      .content-wyb {
        p {
          font-size: 1rem;
          margin-bottom: 15px;
          line-height: 140%;
        }
      }
      .box-information {
        padding: 15px 30px;
        width: auto;
      }
    }
    @media (max-width: 767px) {
      padding: 125px 0 55px;
      min-height: 300px;
      .content-img-wyb-1 {
        display: none;
      }
      .content-wyb {
        text-align: center;
        padding: 20px 15px;
        p {
          margin: auto;
          text-align: center;
        }
      }
    }
    @media (max-width: 576px) {
      h2.title {
        font-size: clamp(1.5rem, 3vw, 2.1rem);
      }
      .content-wyb {
        p {
          text-align: center;
          font-size: 0.9rem;
          line-height: 140%;
          font-weight: 400;
        }
      }
    }
  }
  .section-2 {
    margin-top: 0px;
    padding: 56px 24px 56px;
    .content-wib {
      color: #009750;
      margin-left: auto;
      margin-right: auto;
      max-width: 768px;
      overflow: visible;
      box-shadow: 5px 5px 25px 5px rgba(0, 0, 0, 0.13);
      background: #fff;
      padding: 20px;
      border-radius: 15px;
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      h2.title {
        font-size: clamp(1.2rem, 3vw, 2.2rem);
      }
      &:hover,
      &:active,
      &:active {
        p,
        h2 {
          position: relative;
        }
        color: #fff;
        border-radius: 20px;
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
          background-color: #28a745;
          -webkit-transition: all 0.3s ease 0s;
          -moz-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
          opacity: 1;
          border-radius: 15px;
        }
      }
    }
    @media (min-width: 991px) {
      margin-top: -70px;
      padding: 0 24px 56px;
    }
    @media (max-width: 1440px) {
      .content-wib {
        h2.title {
          font-size: clamp(1.2rem, 3vw, 1.6rem);
        }
        p {
          font-size: 1rem;
          margin-bottom: 15px;
          line-height: 140%;
        }
      }
    }
    @media (max-width: 576px) {
      padding: 20px;
      .content-wib {
        h2.title {
          font-size: clamp(1.5rem, 3vw, 2.1rem);
        }
        p {
          text-align: center;
          font-size: 0.9rem;
          line-height: 140%;
          font-weight: 400;
        }
      }
    }
  }
  .section-3 {
    min-height: 215px;
    overflow: hidden;
    padding-top: 90px;
    padding-bottom: 90px;
    background-color: #f2f4f6;
    h2.title {
      font-size: clamp(1.2rem, 3vw, 1.9rem);
      max-width: 821px;
      text-align: center;
      margin: auto;
    }
    .list-wyb {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .box-wyb {
      border-radius: 16px;
      box-sizing: border-box;
      position: relative;
      background-color: #fff;
      padding: 15px;
      min-height: 310px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin: 1.2rem auto;
      width: 240px;
      border: none;
      border-top: 3px solid #0d9881;
      border-bottom: 2px solid #88c24b;
      z-index: 10;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.08);

      .icon {
        height: 2.5rem;
        margin-bottom: 1rem;
        width: 2.5rem;
        display: flex;
        align-items: flex-end;
        img {
          color: #88c24b;
          width: 100%;
          height: auto;
        }
      }
      h3 {
        color: #333;
        font-size: clamp(15px, 3vw, 19px);
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 15px;
        letter-spacing: 0.7px;
        position: relative;
        z-index: 2;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        text-align: center;
      }
      p {
        font-weight: 400;
        font-size: 15px;
        color: #555;
        letter-spacing: 0.88px;
        line-height: 26px;
        position: relative;
        z-index: 2;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        text-align: center;
      }
      &:hover {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
      }
    }
    @media (max-width: 1440px) {
      padding: 40px 0;
      h2.title {
        font-size: clamp(1.2rem, 3vw, 1.6rem);
      }
    }
    @media (max-width: 576px) {
      padding: 20px 0;
      h2.title {
        font-size: clamp(1.5rem, 3vw, 2.1rem);
      }
      .list-wyb {
        margin-top: -1rem;
      }
    }
  }
  .section-4 {
    padding: 50px 0;
    .banner-box {
      @media (min-width: 768px) {
        min-height: 420px;
        padding: 0 64px;
      }
      min-height: 375px;
      padding: 20px;
      background: #000;
      color: #fff;
      align-items: center;
      border-radius: 16px;
      box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
      display: flex;
      overflow: hidden;
      padding: 16px;
      position: relative;
      box-sizing: border-box;
      .bg-img {
        img {
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: cover;
          height: 100%;
          max-width: 860px;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 100;
          right: 0;
          object-fit: cover;
        }
      }
      .bg-color {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 200;
        background: linear-gradient(95.96deg, rgb(0, 0, 0) 40%, rgba(0, 0, 0, 0) 60%);
      }
      .text {
        width: 100%;
        max-width: 425px;
        position: relative;
        z-index: 300;
        h2.title {
          font-size: clamp(1.3rem, 3vw, 1.8rem);
          font-weight: 400;
          line-height: 110%;
          margin-bottom: 24px;
          text-align: initial;
        }
        .detail {
          font-size: 1.125rem;
          text-align: initial;
          margin-bottom: 0;
        }
      }
      @media (max-width: 575px) {
        .bg-color {
          background: linear-gradient(36deg, black 40%, rgba(0, 0, 0, 0) 80%);
        }
      }
    }
    @media (max-width: 1440px) {
      padding: 20px 0;
      .banner-box {
        min-height: 285px;
        max-width: 992px;
        margin: auto;
        .text {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          h2.title {
            font-size: clamp(1.2rem, 3vw, 1.6rem);
            margin-bottom: 5px;
          }
          .detail {
            font-size: 1rem;
            margin-bottom: 0 !important;
          }
          .button-section {
            .btn-register {
              margin: 5px 0 10px;
            }
          }
        }
      }
    }
    @media (max-width: 576px) {
      .banner-box {
        min-height: 255px;
        .text {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .detail {
            text-align: center;
          }
          .button-section {
            margin: 10px auto;
          }
        }
      }
    }
  }

  .section-5 {
    padding: 30px 0 30px;
    @media (max-width: 1440px) {
      padding: 10px 0 30px;
    }
  }

  @keyframes leftrightborder {
    0% {
      border-top: 3px solid #0d9881;
      border-bottom: 2px solid #88c24b;
      border-right: 0px solid #0d9881;
      border-left: 0px solid #88c24b;
    }
    100% {
      // border-top: 0px solid #0d9881;
      // border-bottom: 0px solid #88c24b;
      border-right: 3px solid #0d9881;
      border-left: 3px solid #88c24b;
    }
  }
}
